@import "./Global.scss";

* {
  &::-webkit-scrollbar {
    width: 4px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    border-radius: 4px;
    background-color: var(--color-bg-main);
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background-color: var(--color-text-secondary);
    border-radius: 4px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background-color: var(--color-text-primary);
  }
}

input {
  color: inherit;
  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    padding-right: 22px;
  }
  &::-moz-placeholder {
    /* Firefox 19+ */
    padding-right: 22px;
  }
  &:-ms-input-placeholder {
    /* IE 10+ */
    padding-right: 22px;
  }
  &:-moz-placeholder {
    /* Firefox 18- */
    padding-right: 22px;
  }

  /* Hide arrows from input number */
  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type="number"] {
    -moz-appearance: textfield;
  }
}

h1,
h2,
h3,
h4 {
  color: inherit;
}

// By default, `<h1>`-`<h6>` all receive top and bottom margins. We nuke the top
// margin for easier control within type scales as it avoids margin collapsing.
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
}

h1 {
  font-size: 2.5rem;
  font-weight: 600;
}

h2 {
  font-size: 2rem;
  font-weight: 600;
}

h3 {
  font-size: 1.5rem;
  font-weight: 700;
}

h4 {
  font-size: 1.25rem;
  font-weight: 600;
}

p {
  color: var(--color-text-secondary);
  margin-top: 0;
  margin-bottom: 1em;
}
